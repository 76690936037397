var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _vm._l(_vm.details, function (obj, index) {
        return _c(
          "div",
          { key: "" + index + obj.id },
          [
            _vm.inputVal.book_entries.entry_details[obj.id]
              ? _c("entry-detail", {
                  attrs: {
                    name: _vm.noName ? "" : _vm.name + " " + (index + 1),
                    conf: obj,
                    canDelete: _vm.canDelete,
                    rules: _vm.rules,
                  },
                  on: {
                    select: _vm.onSelectEntryDetail,
                    unselect: _vm.onUnselectEntryDetail,
                    delete: _vm.onDeleteEntryDetail,
                  },
                  model: {
                    value: _vm.inputVal.book_entries.entry_details[obj.id],
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.inputVal.book_entries.entry_details,
                        obj.id,
                        $$v
                      )
                    },
                    expression: "inputVal.book_entries.entry_details[obj.id]",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("b-row", [
        _c("div", { staticClass: "col-md-6" }, [
          _c("b", [
            _vm._v("Totale: " + _vm._s(_vm.toLocaleCurrency(_vm.total))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _vm.enableRemainder
            ? _c("b", [
                _vm._v(
                  "Residuo: " + _vm._s(_vm.toLocaleCurrency(_vm.remainder))
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }