var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-row", [
    _vm.name
      ? _c("div", { staticClass: "col-md-12" }, [
          _c("strong", { staticClass: "inner-title" }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c("base-select", {
          attrs: {
            id: _vm.conf.id,
            vid: "select" + _vm.conf.id,
            name: "type",
            label: "Tipo Cassa",
            options: _vm.conf.options,
          },
          on: { select: _vm.onSelect, remove: _vm.onRemove },
          model: {
            value: _vm.inputVal.type,
            callback: function ($$v) {
              _vm.$set(_vm.inputVal, "type", $$v)
            },
            expression: "inputVal.type",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-4" },
      [
        _c("base-currency", {
          attrs: {
            id: "bc-" + _vm.conf.id,
            vid: "currency" + _vm.conf.id,
            name: "amount",
            label: "Importo",
            options: { currency: "EUR", locale: "it-IT" },
            placeholder: "Inserisci un importo",
            disabled: !_vm.inputVal.type,
            rules: _vm.inputVal.type ? _vm.rules : {},
          },
          model: {
            value: _vm.inputVal.amount,
            callback: function ($$v) {
              _vm.$set(_vm.inputVal, "amount", $$v)
            },
            expression: "inputVal.amount",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.inputVal.type && _vm.canDelete
      ? _c(
          "div",
          { staticClass: "form-group col-md-2 align-self-end" },
          [
            _c(
              "b-button",
              {
                staticClass: "mt-1 mr-1",
                attrs: { size: "sm", variant: "lisaweb", title: "Rimuovi" },
                on: {
                  click: function ($event) {
                    return _vm.onDelete(_vm.conf.id)
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "trash" } })],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }